<template>
  <div>
    <el-dialog
	  title="编辑计费规则"
      :visible.sync="dialogVisible"
      width="700px"
      :close-on-click-modal='false'
      custom-class='dialog'
	  @close='resetForm'
	>
		<el-form ref="form" :model="form" label-width="140px" :rules="rules" style='padding:0'>
			<el-form-item :label="$t('searchModule.Vehicle_type')" prop='carType'>
				 <el-select v-model="form.carType" placeholder="请选择" @change="getCarGroupList" disabled>
					<el-option :value="0" label="小型车">小型车</el-option>
					<el-option :value="2" label="大型车">大型车</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="车辆组" prop='parkCarGroupId'>
				 <el-select v-model="form.parkCarGroupId" placeholder="请选择" multiple collapse-tags>
					 <el-option
					   :label="value.parkCarGroupName"
					   :value="value.parkCarGroupId"
					   v-for='value in carGroupList'
					   :key='value.parkCarGroupId'
					  >
					  </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="计费规则" prop='feeId'>
				<el-select v-model="form.feeId" placeholder="请选择">
					   <el-option
					     :label="value.feeName"
						 :value="value.feeId"
						 v-for='value in feeList'
						 :key='value.feeCode'
					   >
					   </el-option>
				</el-select>
			</el-form-item>
		</el-form>
        <div slot="footer">
          <el-button type="primary" @click="save" :loading="saveLoading">{{ $t('button.preservation') }}</el-button>
          <el-button @click="resetForm">取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      feeSceneId: '',
      form: {
	     carType: '',
	     parkCarGroupId: [],
	     feeId: ''
      },
	  feeList: [],
	  carGroupList: [],
	  // 之前的车辆组ID对应的车辆类型
	  oldCarType: '',
	  // 之前的车辆组ID
	  carGroupIds: '',
	  rules: {
		   carType: {
			   required: true,
			   message: '请选择车辆类型',
			   trigger: 'change'
		   },
		   parkCarGroupId: {
		       type: 'array',
			   required: true,
			   message: '请选择车辆组',
			   trigger: 'change'
		   },
		   feeId: {
			   required: true,
			   message: '请选择计费规则',
			   trigger: 'change'
		   }
	  },
	  saveLoading: false
    }
  },
  methods: {
    // 打开窗口
    open() {
       this.dialogVisible = true;
	   this.form.carType = '';
	   this.form.parkCarGroupId = [];
	   this.form.feeId = '';
    },
    // 保存
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
		  let parms = {}
		  parms.parkCarGroupId = this.form.parkCarGroupId.join(',');
		  parms.feeId = this.form.feeId;
		  this.saveLoading = true;
          let url = '/acb/2.0/bacb/msfeeScene/modify';
          this.$axios.post(url, {
            data: {
              feeSceneId: this.feeSceneId,
              ...parms
            }
          }).then(res => {
            if (res.state == 0) {
			   setTimeout(() => {
			   	   this.saveLoading = false;
				   this.dialogVisible = false;
				   this.$emit('searchData');
			   }, 2000)
            } else {
			   this.saveLoading = false;
               this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
               })
            }
          }).catch(() => {
		    this.saveLoading = false;
		  })
        } else {
          return false;
        }
      });
    },


	// 获取详情
    getDetail(id) {
	  // console.log('对应ID:' + id)
      this.feeSceneId = id;
	  this.getFeeList();
	  // this.getCarGroupList();
	  this.getFormInfo();
    },


   // 查询计费规则
   getFeeList () {
		// console.log(2)
		this.feeList = [];
		let url = '/acb/2.0/bacb/msfee/listByOperationId';
		this.$axios.get(url, {
			data: {
				operationId: this.$route.query.operationId
			}
		}).then(res => {
			if (res.state == 0) {
				this.feeList = res.value || [];
			} else {
				this.$alert(res.desc, this.$t('pop_up.Tips'), {
					confirmButtonText: this.$t('pop_up.Determine')
				 })
			}
		})
   },

    // 查询车辆组
    getCarGroupList(val) {
	  // console.log(1)
	  this.form.parkCarGroupId = [];
	  this.carGroupList = [];
	  this.$refs.form.clearValidate('parkCarGroupId');
	  let url = '/acb/2.0/bacb/msfeeScene/listParkCarGroup';
	  this.$axios.get(url, {
		data: {
		  childParkId: this.$route.query.parkId,
		  parkId: this.$route.query.parentId
		}
	  }).then(res => {
		if (res.state == 0) {
		  this.carGroupList = res.value || [];
		} else {
		  this.$alert(res.desc, this.$t('pop_up.Tips'), {
			confirmButtonText: this.$t('pop_up.Determine')
		  })
		}
	  })
   },

   // 获取详情
   getFormInfo() {
		this.$axios.get('/acb/2.0/bacb/msfeeScene/detail', {
		   data: {
			feeSceneId: this.feeSceneId
		   }
		}).then(res => {
		  this.loading = false;
		  if (res.state == 0) {
			   this.form.carType = res.value.carType;
			   // 设置之前的车辆组ID
			   this.carGroupIds = res.value.parkCarGroupId;
			   // 设置之前的车辆组ID对应的车辆类型
			   this.oldCarType = res.value.carType;
			   this.getCarGroupList(this.form.carType);
			   this.form.parkCarGroupId = res.value.parkCarGroupId.split(',');
			   this.form.feeId = res.value.feeId
		  } else {
			 this.$alert(res.desc, this.$t('pop_up.Tips'), {
				 confirmButtonText: this.$t('pop_up.Determine')
			 })
		  }
		})
   },

   // 取消
   resetForm () {
	  this.dialogVisible = false;
	  this.$refs.form.resetFields();
   }
  },
  created() {
  }
}
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>

